<template>
  <BaseDialog :show="true" title="預約行事曆顯示設定" @confirm="onSubmit(handleSubmit)" @close="$emit('close')">
    <el-form ref="formRef" :model="formData" :rules="formRules">
      <el-form-item label="顯示內容設定" prop="displayContent">
        <el-select v-model="formData.displayContent" placeholder="">
          <el-option
            v-for="option in displayContentOptions"
            :key="option.value"
            :label="`顯示${option.label}`"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="顯示顏色設定">
        <el-select v-model="formData.displayColor" placeholder="">
          <el-option
            v-for="option in displayColorOptions"
            :key="option.value"
            :label="`顯示${option.label}`"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { useDisplaySettings } from '@/use/displaySettings'
import { noEmptyRules } from '@/validation'
import { usePermissions } from '@/use/permissions'
import { filter } from 'lodash'

export default defineComponent({
  name: 'ReservationCalendarDisplaySettingModal',
  components: { BaseDialog },
  emits: ['close', 'refresh'],
  setup (props, { emit }) {
    const { checkAction } = usePermissions()

    const { initFormData, formData, formRef, onSubmit } = useBaseForm()
    const { getDisplaySettings, displaySettings, updateDisplaySettings } = useDisplaySettings('reservationCalendar', {
      displayContent: 'serviceUnit-service',
      displayColor: 'order-status',
    })
    const formRules = {
      displayContent: [noEmptyRules()],
      displayColor: [noEmptyRules()],
    }

    const displayContentOptions = computed(() => {
      const list = [
        { label: '服務人員與服務項目', value: 'serviceUnit-service' },
        { label: '服務設備與服務項目', value: 'resource-service' },
        { label: '會員名稱與服務項目', value: 'member-service' },
      ]
      const omitList = []
      if (!checkAction('admin.resourceItem.page'))omitList.push('resource-service')
      const result = filter(list, item => !omitList.includes(item.value))
      return result
    })
    const displayColorOptions = [
      { label: '預約訂單狀態顏色', value: 'order-status' },
      { label: '服務人員顏色', value: 'service-unit' },
    ]

    initFormData({
      displayContent: 'serviceUnit-service',
      displayColor: 'order-status',
    })

    const handleSubmit = () => {
      updateDisplaySettings({ ...formData })
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = displaySettings.value
      formData.displayContent = config.displayContent
      formData.displayColor = config.displayColor
    }

    onMounted(() => {
      getDisplaySettings()
      syncData()
    })

    return {
      onSubmit,
      formRef,
      formData,
      formRules,
      handleSubmit,
      displayContentOptions,
      displayColorOptions,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
